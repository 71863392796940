@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #c59012;
  --color-second: #212121;
}

ul,
ol {
  list-style-type: disc; /* Tipo de bullet para listas não ordenadas */
  padding-left: 20px; /* Espaçamento à esquerda */
  margin-bottom: 15px; /* Espaçamento inferior */
}

li {
  margin-bottom: 5px; /* Espaçamento entre itens da lista */
}

/* Estilização para links dentro do conteúdo */
a {
  color: #007bff; /* Cor padrão para links */
  text-decoration: none; /* Remove sublinhado padrão */
}

a:hover {
  text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
}

/* Outras estilizações globais */
body {
  font-family: "Arial", sans-serif; /* Fonte padrão para o corpo do documento */
  line-height: 1.6; /* Espaçamento entre linhas */
  margin: 0; /* Remove margens padrão do corpo */
  padding: 0; /* Remove padding padrão do corpo */
  background-color: #f7f7f7; /* Cor de fundo para o corpo */
  color: #333; /* Cor padrão para o texto */
  overflow: hidden;
}

h1 {
  font-size: 2.5rem; /* Tamanho da fonte para h1 */
}

h2 {
  font-size: 2rem; /* Tamanho da fonte para h2 */
}

h3 {
  font-size: 1.8rem; /* Tamanho da fonte para h3 */
}

.modal-enter {
  opacity: 0;
}
.modal-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

::-webkit-scrollbar {
  height: 4px;
  width: 5px;
  border-radius: 8px;
  background: #151517d9;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a3a4a8d9;
}

.truncate-multi {
  overflow: auto;
  height: 87px;
}

.ant-tabs-tab {
  background-color: #e9e9e9 !important;
  color: #5e5e5e !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  border: 1px solid #c0c0c0 !important;
  border-radius: 2px !important;
  border-bottom: 0px !important;
}

.ant-tabs-nav {
  margin: 0px !important;
}

.ant-tabs-tab-active {
  font-weight: 500 !important;
  background-color: #212121 !important;
  .ant-tabs-tab-btn {
    color: white !important;
  }
}

.ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: #979797 #e0e0e0;
  border-radius: 16px !important;
}

.ant-table-body::-webkit-scrollbar {
  background: #979797;
  border-radius: 8px;
}

.ant-table-body::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 16px !important;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #979797;
  border: 2px solid #e0e0e0;
}

.custom-modal.ant-modal-content {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-modal.custom-modal .ant-modal-content {
  padding: 0 !important;
  background-color: transparent !important;
}

.ant-modal.custom-modal .ant-modal-body {
  background-color: white !important;
}

.ant-modal.custom-modal .ant-modal-header {
  background-color: #212121 !important;
  margin-bottom: 0 !important;
}

.ant-steps-item-description {
  width: 140px !important;
  display: flex !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.loader {
  width: 40px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #080808 90%, #0000);
  background: var(--_g), var(--_g), var(--_g);
  background-size: 25% 25%;
  animation: l17 1s infinite;
}

@keyframes l17 {
  0% {
    background-position: 0% -100%, 50% -100%, 100% -100%;
  }
  16.67% {
    background-position: 0% 50%, 50% -100%, 100% -100%;
  }
  33.33% {
    background-position: 0% 50%, 50% 50%, 100% -100%;
  }
  45%,
  55% {
    background-position: 0% 50%, 50% 50%, 100% 50%;
  }
  66.67% {
    background-position: 0% 200%, 50% 50%, 100% 50%;
  }
  83.33% {
    background-position: 0% 200%, 50% 200%, 100% 50%;
  }
  100% {
    background-position: 0% 200%, 50% 200%, 100% 200%;
  }
}

.ant-input-suffix .ant-input-password-icon {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
}
